// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mdc-data-table__table {
  max-width: 95%;
  min-width: 50%;
}

.threshold-color {
  background-color: rgb(255, 95, 95);
  color: white;
}

.svvTrialTile {
  display: block !important;
  position: absolute;
  top: 5px;
  justify-content: center;
}

.pic-size {
  height: 60px;
}

.cell-padding {
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/vestibular/svv-results/svv-results.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,cAAA;AACJ;;AAEA;EACI,kCAAA;EACA,YAAA;AACJ;;AAEA;EACA,yBAAA;EACA,kBAAA;EACA,QAAA;EACA,uBAAA;AACA;;AAEC;EACG,YAAA;AACJ;;AAEC;EACG,YAAA;AACJ","sourcesContent":[".mdc-data-table__table {\n    max-width: 95%;\n    min-width: 50%;\n}\n\n.threshold-color {\n    background-color: rgb(255, 95, 95);\n    color:white\n}\n\n.svvTrialTile {\ndisplay:block !important;\nposition: absolute;\ntop: 5px;\njustify-content: center;\n }\n\n .pic-size {\n    height:60px;\n }\n\n .cell-padding {\n    padding: 5px;\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
